html[dir="rtl"] body {
  text-align: right; }
  html[dir="rtl"] body * {
    letter-spacing: 0; }

.rtl {
  text-align: right;
  direction: rtl; }

.rtl .nav {
  padding-right: 0; }

.rtl .navbar-nav .nav-item {
  float: right; }

.rtl .navbar-nav .nav-item + .nav-item {
  margin-right: 1rem;
  margin-left: inherit; }

.rtl th {
  text-align: right; }

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem; }

.rtl .dropdown-menu {
  right: 0;
  text-align: right; }

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit; }

.rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.25rem 0.25rem 0; }

.rtl .btn-group > .btn:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem; }

.rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 0.25rem 0 0 0.25rem; }

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit; }

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px; }

.rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0; }

.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem; }

.rtl .input-group > .custom-select:not(:first-child),
.rtl .input-group > .form-control:not(:first-child) {
  border-radius: 0.25rem 0 0 0.25rem; }

.rtl .input-group > .custom-select:not(:last-child),
.rtl .input-group > .form-control:not(:last-child) {
  border-radius: 0 0.25rem 0.25rem 0; }

.rtl .input-group > .custom-select:not(:last-child):not(:first-child),
.rtl .input-group > .form-control:not(:last-child):not(:first-child) {
  border-radius: 0; }

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem; }

.rtl .custom-control-indicator {
  right: 0;
  left: inherit; }

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem; }

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit; }

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px; }

.rtl .close {
  float: left; }

.rtl .modal-header .close {
  margin: -15px auto -15px -15px; }

.rtl .modal-footer > :not(:first-child) {
  margin-right: .25rem; }

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0; }

.rtl .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0; }

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit; }

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit; }

.rtl .offset-1 {
  margin-right: 8.3333333333%;
  margin-left: 0; }

.rtl .offset-2 {
  margin-right: 16.6666666667%;
  margin-left: 0; }

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0; }

.rtl .offset-4 {
  margin-right: 33.3333333333%;
  margin-left: 0; }

.rtl .offset-5 {
  margin-right: 41.6666666667%;
  margin-left: 0; }

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0; }

.rtl .offset-7 {
  margin-right: 58.3333333333%;
  margin-left: 0; }

.rtl .offset-8 {
  margin-right: 66.6666666667%;
  margin-left: 0; }

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0; }

.rtl .offset-10 {
  margin-right: 83.3333333333%;
  margin-left: 0; }

.rtl .offset-11 {
  margin-right: 91.6666666667%;
  margin-left: 0; }

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0; }
  .rtl .offset-sm-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-sm-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-sm-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-sm-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-sm-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-sm-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-sm-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-sm-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; } }

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0; }
  .rtl .offset-md-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-md-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-md-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-md-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-md-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-md-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-md-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-md-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; } }

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0; }
  .rtl .offset-lg-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-lg-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-lg-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-lg-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-lg-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-lg-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-lg-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-lg-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; } }

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0; }
  .rtl .offset-xl-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-xl-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-xl-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-xl-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-xl-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-xl-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-xl-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-xl-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; } }

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important; }

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important; }

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important; }

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important; }

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important; }

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important; }

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important; }

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important; }

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important; }

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important; }

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important; }

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important; }

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important; }

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important; }

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important; }

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important; }

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important; }

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important; }

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important; }

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important; }

.rtl .mr-auto,
.rtl .mx-auto {
  margin-right: 0 !important;
  margin-left: auto !important; }

.rtl .ml-auto,
.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }

@media (min-width: 576px) {
  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-sm-auto,
  .rtl .mx-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-sm-auto,
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-md-auto,
  .rtl .mx-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-md-auto,
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-lg-auto,
  .rtl .mx-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-lg-auto,
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-xl-auto,
  .rtl .mx-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-xl-auto,
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; } }

.rtl .navbar-nav .nav-item + .nav-item {
  margin-right: 0;
  margin-left: 0; }

.home-banner .bannerInnerWrap .bannerSloganWrap {
  right: auto;
  left: 0; }
  .home-banner .bannerInnerWrap .bannerSloganWrap h1:after {
    left: auto;
    right: 0; }

.home-banner .bannerInnerWrap .bannerSlider .slide .bgShape {
  right: auto;
  left: 0; }

.home-banner .bannerInnerWrap .bannerSlider .slide .bannerImg {
  left: auto;
  right: 5%; }

.home-banner .bannerSlider {
  direction: ltr; }

.filter_box ul.select-dropdown {
  left: 0 !important; }

[dir=rtl] .bannerSlider .slick-slide {
  float: left; }

.home-section.initiatives .initiatives_wrapper .initiative_slier_box .initiative_box .top_box .title {
  letter-spacing: 0; }

.home-section.tracks:before {
  right: 0;
  left: auto; }

.home-section.initiatives .initiatives_wrapper .initiative_slier_box .initiative_box .top_box .hover_box .link_ {
  left: .5em;
  right: auto; }

.home-section.initiatives:before {
  left: 0;
  right: auto; }

.home-section.initiatives:after {
  left: 10%;
  right: auto; }

.agenda-section-wrapper .days-category > .js-tabs_control > li small {
  letter-spacing: 0; }

.agenda-section-wrapper .themes-cat .js-tabs_panel {
  direction: ltr; }

.agendaSwiperTabTopWrap {
  direction: ltr; }

.timetable .time-entry {
  direction: rtl; }

.timetable .time-entry {
  /*     padding: 1em 1.5em 1em 1em;
		&:after {
			right:0;
			left: auto;
		}
		&:hover{
			&:before {
				    transform: translateX(-.5em);
			}
			small {
				 transform: translateX(-.5em);
			}
		} */ }
  .timetable .time-entry:before,
  .timetable .time-entry small {
    direction: rtl; }

.select-wrapper .dropdown-content {
  padding-right: 0; }

.filter_box {
  left: 1em;
  right: auto; }
  .filter_box .select-wrapper {
    padding: 0 1.5em 0 3.2em; }
    .filter_box .select-wrapper:after {
      left: .7em;
      right: auto; }

.home-section.initiatives:before {
  right: auto;
  left: 10.2%; }

@media only screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 650px) {
  .banner .container .banner-text {
    padding: 2em 3em 2em 0; }
    .banner .container .banner-text .header {
      font-size: 3.6vw; }
  .banner .container .summit_box .s_btn .text {
    padding: .9em 1.5em; }
  .banner .container .summit_box .s_btn .icon_ {
    font-size: 1.1rem; } }

@media only screen and (max-width: 980px) and (min-width: 768px) {
  .banner .container .summit_box {
    transform: translateX(20%); }
  .banner .container:before {
    transform: translateX(-22%) translateY(-29%); } }

@media only screen and (max-width: 767px) {
  .banner .container .summit_box {
    transform: none; }
  .banner .container:before {
    transform: translateX(-30%) translateY(-29%); }
  .banner .container .banner-text {
    padding: 2em 1.5em 1em; }
  .filter_box {
    left: auto;
    right: auto; } }
